<template>
  <div>
    <div
      id="showPdf"
      class="mb-2"
    />
    <div v-show="false">
      <table id="table-pasien">
        <tbody>
          <tr>
            <td>Nomor Sediaan</td>
            <td>{{ tableShow.pNoSediaan }}</td>
            <td>NIK</td>
            <td>{{ tableShow.pNik }}</td>
          </tr>
          <tr>
            <td>Nama</td>
            <td>{{ tableShow.pNama }}</td>
            <td>Nomor BPJS</td>
            <td>{{ tableShow.pNoBpjs }}</td>
          </tr>
          <tr>
            <td>Jenis Kelamin</td>
            <td>{{ tableShow.pJenisKelamin }}</td>
            <td>Nomor RM</td>
            <td>{{ tableShow.pNoRekamMedis }}</td>
          </tr>
          <tr>
            <td>Umur / Tgl Lahir</td>
            <td>{{ tableShow.pUmurTglLahir }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Alamat Pasien</td>
            <td>{{ tableShow.pAlamat }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table id="table-info-pemeriksaan">
        <tbody>
          <tr>
            <td>Tanggal Diterima</td>
            <td>{{ tableShow.pTanggalDiterima }}</td>
            <td>{{ tableShow.labelPdf1 }}</td>
            <td>{{ tableShow.valuePdf1 }}</td>
          </tr>
          <tr>
            <td>Tanggal Dijawab</td>
            <td>{{ tableShow.pTanggalDijawab }}</td>
            <td>{{ tableShow.labelPdf2 }}</td>
            <td>{{ tableShow.valuePdf2 }}</td>
          </tr>
          <tr>
            <td>Sediaan</td>
            <td>{{ tableShow.pSediaan }}</td>
            <td>{{ tableShow.labelPdf3 }}</td>
            <td>{{ tableShow.valuePdf3 }}</td>
          </tr>
          <tr>
            <td>Lokasi Asal Jaringan</td>
            <td>
              <span v-html="tableShow.pLokasiAsalJaringan"> </span>
            </td>
            <td>{{ tableShow.labelPdf4 }}</td>
            <td>{{ tableShow.valuePdf4 }}</td>
          </tr>
          <tr v-if="tableShow.pJenisPemeriksaan == 'Histopatologi'">
            <td>Vol Fiksasi 10x Vol Spesimen</td>
            <td>{{ tableShow.pIsVolFiksasi10x }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table id="table-diagnosa">
        <tbody>
          <tr>
            <td>Diagnosa Klinik</td>
            <td>
              <span v-html="tableShow.pDiagnosaKlinis"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-keterangan-klinik">
        <tbody>
          <tr>
            <td>Keterangan Klinik</td>
            <td>
              <span v-html="tableShow.pKeteranganKlinis"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-riwayat-lab-pa">
        <tbody>
          <tr>
            <td>Riwayat Hasil PA Sebelumnya</td>
            <td>
              <span v-html="tableShow.pRiwayatLabPa"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-makroskopik">
        <tbody>
          <tr>
            <td>Makroskopik</td>
            <td>
              <span v-html="tableShow.pMakroskopik"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-mikroskopik">
        <tbody>
          <tr>
            <td>Mikroskopik</td>
            <td>
              <span v-html="tableShow.pMikroskopik"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-kesimpulan">
        <tbody>
          <tr>
            <td>Kesimpulan</td>
            <td>
              <span v-html="tableShow.pKesimpulan"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-topography">
        <tbody>
          <tr>
            <td>Topography</td>
            <td>
              <span v-html="tableShow.pTopography"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-morphology">
        <tbody>
          <tr>
            <td>Morphology</td>
            <td>
              <span v-html="tableShow.pMorphology"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-catatan">
        <tbody>
          <tr>
            <td>Catatan</td>
            <td>
              <span v-html="tableShow.pCatatan"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-anjuran">
        <tbody>
          <tr>
            <td>Anjuran</td>
            <td>
              <span v-html="tableShow.pAnjuran"> </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="table-dokter">
        <tbody>
          <tr>
            <td>Dokter Asisten,</td>
            <td>Dokter Pemeriksa,</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>{{ tableShow.pDokterPemeriksa }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { jsPDF } from 'jspdf';
import autotable from "jspdf-autotable";
import qr from 'qrcode'
import { ref } from '@vue/composition-api';
import moment from 'moment'
import pdfobject from "pdfobject";

import ApiPemeriksaan from '@/composables/pemeriksaan/pemeriksaan';
import helper from '@/libs/helper'

export default {
  setup(props, context) {
    const g = context.root;

    const apiPemeriksaan = new ApiPemeriksaan(g);
    const tableShow = ref({
      pJenisPemeriksaan: '',
      pNoSediaan: '',
      pNik: '',
      pNama: '',
      pNoBpjs: '',
      pUmurTglLahir: '',
      pNoRekamMedis: '',
      pJenisKelamin: '',
      pAlamat: '',
      pTanggalDiterima: '',
      pJamOperasi: '',
      pTanggalDijawab: '',
      pJamFiksasi: '',
      pSediaan: '',
      pJumlahJaringan: '',
      pLokasiAsalJaringan: '',
      pFiksasi: '',
      pTopography: '',
      pIsVolFiksasi10x: '',
      pDiagnosaKlinis: '',
      pKeteranganKlinis: '',
      pRiwayatLabPa: '',
      pMakroskopik: '',
      pMikroskopik: '',
      pKesimpulan: '',
      pDerajatHistopatologi: '',
      pMorphology: '',
      pIcdO: '',
      pDokterPemeriksa: '',
      pCatatan: '',
      pAnjuran: '',
      labelPdf1: 'Jam Mulai Operasi',
      labelPdf2: 'Jam Mulai Fiksasi',
      labelPdf3: 'Jumlah Wadah / Jaringan yang Diterima',
      labelPdf4: 'Fiksasi yang Digunakan',
      valuePdf1: '',
      valuePdf2: '',
      valuePdf3: '',
      valuePdf4: '',
    });

    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()
      const pageWidth = 8.3
      const lineHeight = 1.5
      const margin = 0.6
      const maxLineWidth = pageWidth - margin
      const fontSize = 10
      const ptsPerInch = 72

      doc.setFont('times', 'normal')
      doc.setFontSize(9)
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.01)
      let xFooter = 10.3
      for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)

          doc.line(margin, xFooter + 0.24, maxLineWidth - 0.1, xFooter + 0.24);
          let footer = doc.splitTextToSize('Informasi yang terkandung dalam dokumen ini adalah informasi rahasia pasien dan khusus ditujukan hanya untuk Dokter Penanggung Jawab Pasien ( DPJP ) atau dokter yang disebutkan di atas. Jika pembaca dokumen ini BUKAN penerima yang dituju, Anda tidak diizinkan melakukan penyebaran, atau menyalin informasi ini. Jika Anda menerima dokumen ini karena kekeliruan, harap segera memberitahu kami melalui telepon dan mengembalikan dokumen asli ini kepada kami.', maxLineWidth - 0.7)
          doc.text(footer, margin, xFooter + 0.35)
          var textHeight = (footer.length * fontSize * lineHeight) / ptsPerInch
          doc.text('iniakulabs©' + moment().format('YYYY'), maxLineWidth - 0.5, xFooter + textHeight + 0.15);
      }
  }

  const convertHtmlToString = str => {
      str = str.replace(/<li>/gi,"•\t");
      str = str.replace(/<\/li>/gi,"<br>");
      // str = str.replace(/(\r\n|\n|\r)/gm,"<br>");
      str = str.replace(/<p>/gi,"");
      str = str.replace(/<\/p>/gi,"<br>");
      let strSplit = str.split('<br>')
      strSplit = strSplit.slice(0, -1)
      str = strSplit.join('<br>')

      return str
  }

    const generatePDF = async (id, params, download) => {
      const result = await apiPemeriksaan.getPesanan(id, params)
      if(!result.isSuccess) helper.showError(g, {request: 'PDF gagal dibuat'}, `Error Get Data`)
      
      generateDoc(result, download)
    }

    const generateDoc = (result, download) => {
      const {
        dokterPemeriksaVal,
        dokterPengirimVal,
        rsKlinikVal,
        noSediaanVal,
        namaVal,
        umurTglLahirVal,
        jenisKelaminVal,
        alamatVal,
        nikVal,
        noBpjsVal,
        noRekamMedisVal,
        tanggalDiterimaVal,
        tanggalDijawabVal,
        sediaanVal,
        lokasiAsalJaringanVal,
        topographyVal,
        waktuOperasiVal,
        waktuFiksasiVal,
        jumlahSediaanVal,
        fiksasiVal,
        isVolFiksasi10xVal,
        diagnosaKlinisVal,
        keteranganKlinisVal,
        riwayatLabPaVal,
        makroskopikVal,
        kesimpulanVal,
        derajatHistopatologiVal,
        morphologyVal,
        icdOVal,
        jenisPemeriksaanVal,
        mikroskopikVal,
        sipVal,
        tempatPraktekVal,
        hpTeleponDokterPemeriksaVal,
        ttdVal,
        logoTempatPraktekVal,
        alamatPraktekVal,
        catatanVal,
        anjuranVal,
      } = result.data.data;

      /*
       * Let's demonstrate string splitting with the first page of Shakespeare's Romeo and Juliet!
       * We'll use a 8.5 x 11 inch sheet, measuring everything in inches.
       */
      const pageWidth = 8.3
      const lineHeight = 1.5
      const margin = 0.6
      const marginTop = 0.48
      const maxLineWidth = pageWidth - margin
      const fontSize = 10
      const ptsPerInch = 72
      const oneLineHeight = (fontSize * lineHeight) / ptsPerInch;
      
      const doc = new jsPDF({
        unit: 'in',
        format: 'a4',
      })
        .setProperties({ title: noSediaanVal })
        .setFont('times', 'normal');

      // text is placed using x, y coordinates
      doc
        .setFont('times', 'bold')
        .setFontSize(10)
        .text(dokterPemeriksaVal, margin, marginTop);

      const tempatPraktek =
        tempatPraktekVal && alamatPraktekVal
          ? `${tempatPraktekVal} ${alamatPraktekVal}`
          : tempatPraktekVal
            ? tempatPraktekVal
            : alamatPraktekVal
              ? alamatPraktekVal
              : ''
      const columns2 = maxLineWidth / 2;
      const sipDokter = doc.splitTextToSize(tempatPraktek, columns2);

      doc
        .setFontSize(10)
        .text(sipDokter, margin, marginTop + (fontSize * lineHeight) / 90);

      doc.text(
        sipVal ? `SIP: ${sipVal}` : '',
        margin,
        (sipDokter.length * fontSize * lineHeight) / ptsPerInch +
        marginTop +
        oneLineHeight -
        0.1
      );

      // qrcode
      let qrcode = '';
      qr.toDataURL(noSediaanVal, function (err, code) {
        if (err) return console.log('error occurred');

        qrcode = code;
      });

      const tinggiGarisPembatas =
        (sipDokter.length * fontSize * lineHeight) / ptsPerInch +
        (marginTop + oneLineHeight - 0.05);

      doc.addImage(
        qrcode,
        'PNG',
        maxLineWidth - 0.7,
        tinggiGarisPembatas - 0.7,
        0.7,
        0.7
      );
      // create a line under heading
      doc
        .setLineWidth(0.01)
        .line(
          margin,
          tinggiGarisPembatas,
          maxLineWidth - 0.1,
          tinggiGarisPembatas
        );

      const title = `HASIL DIAGNOSTIK ${jenisPemeriksaanVal.toUpperCase()}`;
      const textX =
        (doc.internal.pageSize.getWidth() - 0.5 - doc.getTextWidth(title)) /
        2;
      doc
        .setFont('times', 'bold')
        .setFontSize(11.5)
        .text(title, textX, tinggiGarisPembatas + 2 * oneLineHeight);

      const kepadaYth =
        dokterPengirimVal && rsKlinikVal
          ? `${dokterPengirimVal} ( ${rsKlinikVal.toUpperCase()} )`
          : dokterPengirimVal
            ? dokterPengirimVal
            : rsKlinikVal
              ? rsKlinikVal
              : '';
      const splitTextKepadaYth = doc.splitTextToSize(
        `Kepada Yth : ${kepadaYth}`,
        maxLineWidth - 0.7
      );
      doc
        .setFontSize(11)
        .text(
          splitTextKepadaYth,
          margin,
          tinggiGarisPembatas + 4 * oneLineHeight
        );

      let tableShowTemp = {
        pJenisPemeriksaan: jenisPemeriksaanVal,
        pNoSediaan: noSediaanVal,
        pNik: nikVal,
        pNama: namaVal,
        pNoBpjs: noBpjsVal,
        pUmurTglLahir: umurTglLahirVal,
        pNoRekamMedis: noRekamMedisVal,
        pJenisKelamin: jenisKelaminVal,
        pAlamat: alamatVal,
        pTanggalDiterima: tanggalDiterimaVal,
      }
      // tableShow.value.pJenisPemeriksaan = jenisPemeriksaanVal;
      // tableShow.value.pNoSediaan = noSediaanVal;
      // tableShow.value.pNik = nikVal;
      // tableShow.value.pNama = namaVal;
      // tableShow.value.pNoBpjs = noBpjsVal;
      // tableShow.value.pUmurTglLahir = umurTglLahirVal;
      // tableShow.value.pNoRekamMedis = noRekamMedisVal;
      // tableShow.value.pJenisKelamin = jenisKelaminVal;
      // tableShow.value.pAlamat = alamatVal;
      // tableShow.value.pTanggalDiterima = tanggalDiterimaVal;

      if (jenisPemeriksaanVal == 'Histopatologi') {
        tableShowTemp = {
          ...tableShowTemp,
          labelPdf1: 'Jam Mulai Operasi',
          labelPdf2: 'Jam Mulai Fiksasi',
          labelPdf3: 'Jumlah Wadah / Jaringan yang Diterima',
          labelPdf4: 'Fiksasi yang Digunakan',
          valuePdf1: waktuOperasiVal,
          valuePdf2: waktuFiksasiVal,
          valuePdf3: jumlahSediaanVal,
          valuePdf4: fiksasiVal,
        }
        // tableShow.value.labelPdf1 = 'Jam Mulai Operasi';
        // tableShow.value.labelPdf2 = 'Jam Mulai Fiksasi';
        // tableShow.value.labelPdf3 = 'Jumlah Wadah / Jaringan yang Diterima';
        // tableShow.value.labelPdf4 = 'Fiksasi yang Digunakan';

        // tableShow.value.valuePdf1 = waktuOperasiVal;
        // tableShow.value.valuePdf2 = waktuFiksasiVal;
        // tableShow.value.valuePdf3 = jumlahSediaanVal;
        // tableShow.value.valuePdf4 = fiksasiVal;
      } else {
        tableShowTemp = {
          ...tableShowTemp,
          labelPdf1: 'Fiksasi yang Digunakan',
          labelPdf2: '',
          labelPdf3: '',
          labelPdf4: '',

          valuePdf1: fiksasiVal,
          valuePdf2: '',
          valuePdf3: '',
          valuePdf4: '',
        }
        // tableShow.value.labelPdf1 = 'Fiksasi yang Digunakan';
        // tableShow.value.labelPdf2 = '';
        // tableShow.value.labelPdf3 = '';
        // tableShow.value.labelPdf4 = '';

        // tableShow.value.valuePdf1 = fiksasiVal;
        // tableShow.value.valuePdf2 = '';
        // tableShow.value.valuePdf3 = '';
        // tableShow.value.valuePdf4 = '';
      }

      tableShowTemp = {
        ...tableShowTemp,
        pJamOperasi: waktuOperasiVal,
        pTanggalDijawab: tanggalDijawabVal,
        pJamFiksasi: waktuFiksasiVal,
        pSediaan: sediaanVal,
        pJumlahJaringan: jumlahSediaanVal,
        pLokasiAsalJaringan: lokasiAsalJaringanVal,
        pFiksasi: fiksasiVal,
        pTopography: topographyVal,
        pIsVolFiksasi10x: isVolFiksasi10xVal,
        pDiagnosaKlinis: diagnosaKlinisVal,
        pKeteranganKlinis: keteranganKlinisVal,
        pRiwayatLabPa: riwayatLabPaVal,
        pMakroskopik: makroskopikVal,
        pMikroskopik: mikroskopikVal,
        pKesimpulan: kesimpulanVal,
        pMorphology: morphologyVal,
        pIcdO: icdOVal,
        pDokterPemeriksa: dokterPemeriksaVal,
        pCatatan: catatanVal,
        pAnjuran: anjuranVal,
      }
      // tableShow.value.pJamOperasi = waktuOperasiVal;
      // tableShow.value.pTanggalDijawab = tanggalDijawabVal;
      // tableShow.value.pJamFiksasi = waktuFiksasiVal;
      // tableShow.value.pSediaan = sediaanVal;
      // tableShow.value.pJumlahJaringan = jumlahSediaanVal;
      // tableShow.value.pLokasiAsalJaringan = lokasiAsalJaringanVal;
      // tableShow.value.pFiksasi = fiksasiVal;
      // tableShow.value.pTopography = topographyVal;
      // tableShow.value.pIsVolFiksasi10x = isVolFiksasi10xVal;
      // tableShow.value.pDiagnosaKlinis = diagnosaKlinisVal;
      // tableShow.value.pKeteranganKlinis = keteranganKlinisVal;
      // tableShow.value.pRiwayatLabPa = riwayatLabPaVal;
      // tableShow.value.pMakroskopik = makroskopikVal;
      // tableShow.value.pMikroskopik = mikroskopikVal;
      // tableShow.value.pKesimpulan = kesimpulanVal;
      // tableShow.value.pMorphology = morphologyVal;
      // tableShow.value.pIcdO = icdOVal;
      // tableShow.value.pDokterPemeriksa = dokterPemeriksaVal;
      // tableShow.value.pCatatan = catatanVal;
      // tableShow.value.pAnjuran = anjuranVal;

      if (jenisPemeriksaanVal == "Histopatologi") tableShowTemp.pKesimpulan += derajatHistopatologiVal
        // pKesimpulan.value += derajatHistopatologiVal;

      const tempKesimpulan = tableShowTemp.pKesimpulan
      tableShowTemp.pKesimpulan = convertHtmlToString(tableShowTemp.pKesimpulan);
      if (!tableShowTemp.pKesimpulan) tableShowTemp.pKesimpulan = tempKesimpulan

      tableShowTemp = {
        ...tableShowTemp,
        pMikroskopik: convertHtmlToString(tableShowTemp.pMikroskopik),
        pMakroskopik: convertHtmlToString(tableShowTemp.pMakroskopik),
        pKeteranganKlinis: convertHtmlToString(tableShowTemp.pKeteranganKlinis),
        pDiagnosaKlinis: convertHtmlToString(tableShowTemp.pDiagnosaKlinis),
        pRiwayatLabPa: convertHtmlToString(tableShowTemp.pRiwayatLabPa),
      }

      tableShow.value = {
        ...tableShow.value,
        ...tableShowTemp,
      }

      // pMikroskopik.value = convertHtmlToString(pMikroskopik.value);
      // pMakroskopik.value = convertHtmlToString(pMakroskopik.value);
      // pKeteranganKlinis.value = convertHtmlToString(
      //   pKeteranganKlinis.value
      // );
      // pDiagnosaKlinis.value = convertHtmlToString(pDiagnosaKlinis.value);
      // pRiwayatLabPa.value = convertHtmlToString(pRiwayatLabPa.value);

      setTimeout(async () => {
        const body = [
          [
            { content: '' },
            { content: 'Dokter Pemeriksa, ', styles: { halign: 'center' } },
          ],
          ['', 'https://img.lovepik.com/element/40103/6703.png_300.png'],
          ['', ''],
          ['', ''],
          [
            { content: '', styles: { cellPadding: { bottom: 0 } } },
            {
              content: dokterPemeriksaVal,
              styles: { cellPadding: { bottom: 0 } },
            },
          ],
          [
            '',
            hpTeleponDokterPemeriksaVal
              ? 'HP: ' + hpTeleponDokterPemeriksaVal
              : '',
          ],
        ];

        let isAddPage = true;
        let opsi1 = {
          theme: 'plain',
          tableWidth: 'auto',
          columnStyles: {
            0: {
              font: 'times',
              fontStyle: 'bold',
              cellWidth: 1.3,
              cellPadding: { right: 0.1 },
            },
            1: { font: 'times', cellWidth: 2 },
            2: {
              font: 'times',
              fontStyle: 'bold',
              cellWidth: 1.8,
              cellPadding: { left: 0.2, right: 0.1 },
            },
            3: { font: 'times', cellWidth: 1.9 },
          },
          rowPageBreak: 'auto',
          styles: { cellPadding: { bottom: 0.08 } },
        };

        opsi1 = {
          ...opsi1,
          html: '#table-pasien',
          margin: { left: 0.6 },
          startY: tinggiGarisPembatas +
            oneLineHeight * (4 + splitTextKepadaYth.length),
        }
        // opsi1.html = '#table-pasien';
        // opsi1.margin = { left: 0.6 };
        // opsi1.startY =
        //   tinggiGarisPembatas +
        //   oneLineHeight * (4 + splitTextKepadaYth.length);
        doc.autoTable(opsi1);

        opsi1 = {
          ...opsi1,
          html: '#table-info-pemeriksaan',
          startY: doc.lastAutoTable.finalY,
          margin: { left: 0.6 },
        }
        // opsi1.html = '#table-info-pemeriksaan';
        // opsi1.startY = doc.lastAutoTable.finalY;
        // opsi1.margin = { left: 0.6 };
        doc.autoTable(opsi1);

        let opsi2 = {
          theme: 'plain',
          tableWidth: 'auto',
          columnStyles: {
            0: {
              font: 'times',
              fontStyle: 'bold',
              cellWidth: 1.3,
              cellPadding: { right: 0.1 },
            },
            1: { font: 'times', cellWidth: 5.7 },
          },
          rowPageBreak: 'avoid',
          margin: { left: 0.6 },
          styles: { cellPadding: { bottom: 0.08 } },
        };

        let finalY = 0;

        opsi2 = {
          ...opsi2,
          html: "#table-diagnosa",
          startY: doc.lastAutoTable.finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }

        // opsi2.html = "#table-diagnosa";
        // opsi2.startY = doc.lastAutoTable.finalY;
        // opsi2.willDrawCell = function (data) {
        //   if (data.cell.y + data.cell.height > 10.4) {
        //     data.cell.y = 0.6;

        //     if (isAddPage) {
        //       finalY = data.cell.y + data.cell.height;
        //       doc.autoTableAddPage();
        //       isAddPage = false;
        //     }
        //   }
        // };
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-keterangan-klinik',
          startY: finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-riwayat-lab-pa',
          startY: finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-makroskopik',
          startY: finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-mikroskopik',
          startY: finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-kesimpulan',
          startY: finalY,
          columnStyles: {
            0: {
              font: 'times',
              fontStyle: 'bold',
              cellWidth: 1.3,
              cellPadding: { right: 0.1 },
            },
            1: { font: 'times', fontStyle: 'bold', cellWidth: 5.7 },
          },
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        isAddPage = true;

        opsi2 = {
          ...opsi2,
          html: '#table-topography',
          startY: finalY,
          willDrawCell: function (data) {
            if (data.cell.y + data.cell.height > 10.4) {
              data.cell.y = 0.6;

              if (isAddPage) {
                finalY = data.cell.y + data.cell.height;
                doc.autoTableAddPage();
                isAddPage = false;
              }
            }
          },
        }
        doc.autoTable(opsi2);

        if (jenisPemeriksaanVal == "Histopatologi") {
          finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
          isAddPage = true;

          opsi2 = {
            ...opsi2,
            html: '#table-morphology',
            startY: finalY,
            willDrawCell: function (data) {
              if (data.cell.y + data.cell.height > 10.4) {
                data.cell.y = 0.6;

                if (isAddPage) {
                  finalY = data.cell.y + data.cell.height;
                  doc.autoTableAddPage();
                  isAddPage = false;
                }
              }
            },
          }
          doc.autoTable(opsi2);
        }

        if (catatanVal) {
          doc.setFont('times', 'normal');
          finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
          isAddPage = true;

          opsi2 = {
            ...opsi2,
            html: '#table-catatan',
            columnStyles: {
              0: {
                font: 'times',
                fontStyle: 'bold',
                cellWidth: 1.3,
                cellPadding: { right: 0.1 },
              },
              1: { font: 'times', cellWidth: 5.7 },
            },
            startY: finalY,
            willDrawCell: function (data) {
              if (data.cell.y + data.cell.height > 10.4) {
                data.cell.y = 0.6;

                if (isAddPage) {
                  finalY = data.cell.y + data.cell.height;
                  doc.autoTableAddPage();
                  isAddPage = false;
                }
              }
            },
          }
          doc.autoTable(opsi2);
        }

        if (anjuranVal) {
          doc.setFont('times', 'normal');
          finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
          isAddPage = true;

          opsi2 = {
            ...opsi2,
            html: '#table-anjuran',
            columnStyles: {
              0: {
                font: 'times',
                fontStyle: 'bold',
                cellWidth: 1.3,
                cellPadding: { right: 0.1 },
              },
              1: { font: 'times', cellWidth: 5.7 },
            },
            startY: finalY,
            willDrawCell: function (data) {
              if (data.cell.y + data.cell.height > 10.4) {
                data.cell.y = 0.6;

                if (isAddPage) {
                  finalY = data.cell.y + data.cell.height;
                  doc.autoTableAddPage();
                  isAddPage = false;
                }
              }
            },
          }
          doc.autoTable(opsi2);
        }

        finalY = isAddPage ? doc.lastAutoTable.finalY : finalY;
        finalY += 0.1;

        if (finalY > 8.8) doc.addPage('a4');

        let tempCell;
        let textPos

        doc.autoTable({
          theme: 'plain',
          body,
          tableWidth: 'auto',
          startY: 9,
          margin: { left: 0.5 },
          columnStyles: {
            0: { font: 'times', cellWidth: 4 },
            1: { font: 'times', cellWidth: 3, halign: 'center' },
          },
          rowPageBreak: 'auto',
          styles: { cellPadding: { bottom: 0.08 } },
          didParseCell(data) {
            if (data.row.index === 4 && data.column.index === 1)
              tempCell = data.cell;
          },
          willDrawCell: async function (data) {
            if (
              data.column.index === 1 &&
              data.row.index === 1 &&
              data.cell.section === 'body'
            ) {
              data.cell.text = '';

              textPos = data.cell;
            }
          },
        });

        if (ttdVal) {
          const ttdDokter = await helper.getDataUri(ttdVal);
          doc.addImage(ttdDokter, 'PNG', textPos.x + 0.6, textPos.y, 1.8, 0.9);
        }

        const xTemp = (3 - tempCell.contentWidth) / 2;
        doc.setDrawColor(0, 0, 0);
        doc
          .setLineWidth(0.01)
          .line(
            tempCell.x + xTemp,
            tempCell.y + tempCell.contentHeight,
            tempCell.x + tempCell.contentWidth + 0.02 + xTemp,
            tempCell.y + tempCell.contentHeight
          );

        addFooters(doc);

        if(download == 'download') doc.save(noSediaanVal + ".pdf");
        else if(download == 'show') pdfobject.embed(doc.output('datauristring'), '#showPdf')
        else {
          doc.save(noSediaanVal + ".pdf")
          pdfobject.embed(doc.output('datauristring'), '#showPdf')
        }
      }, 500)
    }

    return {tableShow, generatePDF, generateDoc}
  }
}
</script>
<style lang="scss" scope>
.pdfobject-container {
  height: 80rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>
