const url = '/download/pemeriksaan/'
import GeneralApi from "@/composables/GeneralApi";
import helper from '@/libs/helper'

class DownloadPemeriksaan extends GeneralApi {
  constructor(g) {
    super(g, url);
    this.url = url;
  }

  getPesanan(id) {
    return this.g.$http({
      url: `${this.url}get-pesanan/${id}`,
    })
      .then((res) => ({
        isSuccess: true,
        data: res.data
      }))
      .catch(err => {
        helper.showError(this.g, err, `Error Get Data`)

        return { isSuccess: false }
      })
  }
}

export default DownloadPemeriksaan