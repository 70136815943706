const url = '/pemeriksaan/pemeriksaan/'
import GeneralApi from "@/composables/GeneralApi";
import helper from '@/libs/helper'

class Pemeriksaan extends GeneralApi {
  constructor(g) {
    super(g, url);
    this.url = url;
  }

  getPesanan(id, params) {
    return this.g.$http({
      url: `${this.url}get-pesanan/${id}`,
      headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` },
      params
    })
      .then((res) => ({
        isSuccess: true,
        data: res.data
      }))
      .catch(err => {
        helper.showError(this.g, err, `Error Get Data`)

        return { isSuccess: false }
      })
  }
}

export default Pemeriksaan